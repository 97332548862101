<template>
  <div id="app">
    <!-- 顶部导航 -->
    <Navbar />

    <!-- 主体内容 -->
    <div class="main">
      <router-view />
    </div>

    <!-- 底部内容 -->
    <!-- <Footer/> -->



  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { openNoticeInfo } from '@/api/user.js'
export default {
  name: 'HomeView',
  components: {
    Navbar,
    Footer,

  },
  // beforeCreate() {
  //   // //////////console.logprocess.env.VUE_APP_BASE_API,'什么环境');
  //   if( process.env.VUE_APP_BASE_API === 'https://zx.laoyuip.com.cn'){
  //        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //     if (isMobile) {
  //       //  alert('当前在手机端');
  //       window.location.href = 'https://pp.laoyuip.com.cn'
  //     }
  //   }else{
  //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //     if (isMobile) {
  //       //  alert('当前在手机端');
  //       window.location.href = 'https://dp.laoyuip.com.cn'
  //     }
  //   }   
  // },

  beforeCreate() {
   
    // 判断环境
    const baseUrl = process.env.VUE_APP_BASE_API === 'https://zx.laoyuip.com.cn' ? 'https://pp.laoyuip.com.cn/#/' : 'https://dp.laoyuip.com.cn/#/';
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // 获取当前 URL 并解析参数
    const currentUrl = new URL(window.location.href);
    const promotionCode = currentUrl.searchParams.get('promotion_code');
    // ////////console.logpromotionCode,'获取到的验证码111');
    if (isMobile) {
      let targetUrl = baseUrl;
      if (promotionCode) {
        targetUrl += `?promotion_code=${promotionCode}`;
      }
      window.location.href = targetUrl;
    }
  },
}
</script>


<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  width: 100%;
  height: 100%;
  background: #0E1130;
  margin: 0;
  padding: 0;
  position: relative;
  
}


.main {
  width: 100%;
  height: 100%;
  // height: 1200px;
  background: #F5F9FF;
}

.suspension {
  width: 200px;
  height: 100px;
  background: salmon;
  position: fixed;
  right: 50px;
  bottom: 100px;
}
</style>
